import React from "react";


function TopAppBar({name}){
  return (
    <div className="navbar navbar-glass navbar-sticky h-20">
    <div className="navbar-start w-2">
        {/*<a href="/"><img src={logo} width={40} height={40} alt="logo"></img></a>*/}
        
    </div>
    <div className="navbar-center">
        <h5 className="font-extrabold text-xl text-sky-500">{name}</h5>
        <p className="text-lg"> is for sale!</p>
    </div>
    <div className="navbar-end w-2">
    </div>
    </div>
);
  }


  export default TopAppBar;